<template>
    <div class="col-12">
        <div class="card">
            <Toast />
            <h4>Assinar Pedido</h4>

            <div v-if="envelope" class="col-12 border-panel">
                <div class="grid">

                    <div class="col-8 sm:col-9 lg:col-8">
                        <label for="numeroDocumento"><strong>Número do Pedido:
                            </strong>{{ envelope.numeroDocumento }}</label>
                    </div>

                    <div class="col-8 sm:col-9 lg:col-8">
                        <label for="numeroDocumento"><strong>Número do Envelope:
                            </strong>{{ envelope.idEnvelope }}</label>
                    </div>

                    <div class="col-8 sm:col-9 lg:col-8">
                        <label for="numeroDocumento"><strong>Data de Criação: </strong>{{
                            formatDate(envelope.dataCriacao) }}</label>
                    </div>

                    <div class="col-8 sm:col-9 lg:col-8">
                        <label for="numeroDocumento"><strong>Conclusão das Assinaturas: </strong>{{
                            formatDate(envelope.dataConclusao) }}</label>
                    </div>

                    <div class="col-8 sm:col-9 lg:col-8">
                        <label for="numeroDocumento"><strong>Status do Envelope: </strong>{{ envelope.status }}</label>
                    </div>

                    <div class="col-12 sm:col-12 lg:col-8 border-panel mt-5">
                        <h5>Assinantes</h5>
                        <DataTable :value="filteredSigners" responsiveLayout="scroll">
                            <Column header="Tipo de Assinatura" style="width: 15rem; text-align: left;">
                                <template #body="{ data }">
                                    <span v-if="isGerente(data.email)">Gerente</span>
                                    <span v-else>Cooperado</span>
                                </template>
                            </Column>
                            <Column field="matricula" header="Matrícula" style="width: 10rem; text-align: left;">
                            </Column>
                            <Column field="email" header="Email" style="width: 20rem; text-align: left;"></Column>
                            <Column field="dataHoraAssinatura" header="Data da Assinatura"
                                style="width: 15rem; text-align: left;">
                                <template #body="{ data }">
                                    {{ data.dataHoraAssinatura ? formatDate(data.dataHoraAssinatura) : 'Não assinada' }}
                                </template>
                            </Column>
                            <Column header="Ações" style="width: 10rem; text-align: left;">
                                <template #body="{ data }">
                                    <Button v-if="mostrarBotaoAssinar(data)" @click="openModal(data)"
                                        class="p-button p-component p-button-raised p-button-rounded">
                                        Assinar
                                    </Button>
                                    <div v-if="data.dataHoraAssinatura">

                                        <label>Assinado </label>
                                        <i class="pi pi-check" />
                                    </div>

                                </template>
                            </Column>
                        </DataTable>
                    </div>

                    <div class="col-12 sm:col-12 lg:col-8 border-panel mt-5">
                        <Button @click="visualizarDocumento(envelope.idEnvelope)" icon="pi pi-file-pdf"
                            label="Visualizar Documento" class="p-button-text p-button" />
                    </div>


                    <Dialog v-model:visible="pdfContent" :style="{ width: '90%', height: '90%' }" :modal="true"
                        @hide="fecharVisualizacao">

                        <template #header>
                            <div class="flex align-items-center">
                                <i class="pi pi-unlock mr-1" style="font-size: 1.4rem"></i>
                                <h3 style="margin:0px 5px;">Documento</h3>
                            </div>
                        </template>
                        <iframe :src="pdfContent" width="100%" height="850px"></iframe>
                    </Dialog>

                    <Dialog v-model:visible="showModal" :style="{ width: '30%' }" :modal="true" @hide="closeModal">

                        <template #header>
                            <div class="flex align-items-center">
                                <i class="pi pi-pencil mr-1" style="font-size: 1.4rem"></i>
                                <h3 style="margin:0px 5px;">Assinatura Eletrônica</h3>
                            </div>
                        </template>
                        <p>Ao assinar este documento eletronicamente, você concorda com os termos e condições e
                            reconhece a validade
                            jurídica da assinatura eletrônica.</p>
                        <!-- <div>
                            <input type="checkbox" id="accept" v-model="acceptTerms">
                            <label for="accept">Eu aceito os termos e condições</label>
                        </div> -->
                        <div class="mt-3">
                            <label for="password">Senha : </label>
                            <InputText :class="['mt-1', { 'p-invalid': passwordInvalido }]" id="password"
                                type="password" v-model="password" placeholder="Senha" />
                        </div>
                        <template #footer>
                            <Button label="Assinar" icon="pi pi-pencil" class="p-button-text p-button"
                                @click.prevent="assinarDocumento" :disabled="loading" :loading="loading"
                                loadingIcon="pi pi-spin pi-spinner" />
                            <Button label="Cancelar" icon="pi pi-times" class="p-button-text p-button-danger"
                                @click="closeModal" :disabled="loading" /> </template>
                    </Dialog>
                </div>
            </div>
            <div v-else>
                <p>Carregando... Por favor, aguarde.</p>
            </div>
        </div>
    </div>
</template>

<script>
import AssinaturaEletronicaService from '../../service/AssinaturaEletronica';
import StorageService from '../../service/StorageService';

export default {
    name: 'assinaturaPedido',
    props: {
        numeroDocumento: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            envelope: null,
            pdfContent: null,
            showModal: false,
            selectedSigner: null,
            acceptTerms: false,
            password: '',
            passwordInvalido: false,
            loading: false,
        };
    },
    created() {
        this.buscarEnvelope(this.numeroDocumento);
    },
    computed: {
        filteredSigners() {
            if (!this.envelope || !this.envelope.signers) return [];
            const cooperado = this.envelope.signers.find(signer => signer.matricula);
            const gerente = this.envelope.signers.find(signer => !signer.matricula && signer.email.endsWith('@integrada.coop.br'));
            return [cooperado, gerente].filter(Boolean);
        }
    },
    methods: {

        isGerente(email) {

            if (email === null) {
                return false;
            }

            if (email.endsWith('@integrada.coop.br')) {
                return true;
            }

            return false;

        },

        mostrarBotaoAssinar(data) {

            if (data == null) {
                return false;
            }

            if (!data.dataHoraAssinatura) {
                if (data.email && data.email.endsWith('@integrada.coop.br')) {

                    if (StorageService.getAdmin()) {
                        console.log('Storage email = ', StorageService.getAdmin().email);
                        console.log('data email', data.email);
                        if (StorageService.getAdmin().email == data.email) {
                            return true;
                        }
                    }

                }
            }

            return false;

        },


        buscarEnvelope(numeroDocumento) {

            console.log('Matricula: ', this.$store.state.auth.user.matricula);
            console.log('User: ', this.$store.state.auth.user);
            console.log('auth: ', this.$store.state.auth);
            console.log('state: ', this.$store.state);
            console.log('store: ', this.$store);
            console.log(StorageService.getAdmin());


            AssinaturaEletronicaService.consultarEnvelopeNumeroDocumento(numeroDocumento)
                .then(({ data }) => {
                    this.envelope = data;
                })
                .catch(error => {
                    console.error("Erro ao buscar envelope:", error);
                });
        },

        formatDate(data) {
            if (data == null) {
                return null;
            }
            const date = new Date(data);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');
            const hours = String(date.getUTCHours()).padStart(2, '0');
            const minutes = String(date.getUTCMinutes()).padStart(2, '0');
            const seconds = String(date.getUTCSeconds()).padStart(2, '0');

            return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
        },

        visualizarDocumento(idEnvelope) {
            AssinaturaEletronicaService.downloadDocumento(idEnvelope)
                .then(response => {
                    console.log(response.data);
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    this.pdfContent = URL.createObjectURL(blob);
                })
                .catch(error => {
                    console.error("Erro ao tentar visualizar o PDF:", error);
                    this.$toast.add({
                        severity: "error",
                        detail: error.response.data.message_error,
                        life: 5000
                    });
                });

        },
        fecharVisualizacao() {
            this.pdfContent = null;
        },
        openModal(signer) {
            this.selectedSigner = signer;
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
            this.acceptTerms = false;
            this.password = '';
        },

        async geolocalizacaoUsuario() {
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition((position) => {
                    resolve(position.coords);
                }, (error) => {
                    reject(error);
                });
            });
        },

        async assinarDocumento() {

            try {
                this.loading = true;
                const ip = await AssinaturaEletronicaService.pegarIp();

                const payload = {
                    //limite a no maximo 99 caracteres
                    dispositivo: navigator?.userAgent?.substring(0, 30) || "WEB",
                    idAssinantes: this.selectedSigner.idAssinantes,
                    ip,
                    localizacao: "",//this.selectedSigner.localizacao,
                    email: this.selectedSigner.email,
                    senha: this.password
                };

                const localizacao = await this.geolocalizacaoUsuario();
                payload.localizacao = `${localizacao.latitude},${localizacao.longitude}`;

                await AssinaturaEletronicaService.assinar(payload)
                    .then(_response => {
                        this.closeModal();
                        this.buscarEnvelope(this.numeroDocumento);
                    })
                    .catch(error => {
                        this.closeModal();
                        this.$toast.add({
                            severity: "error",
                            detail: error.response.data.message_error,
                            life: 5000
                        });
                    });
            } catch (e) {
                console.log('Erro ao assinar documento', e);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<!-- <style>
.card {
    padding: 2rem;
}

.signer-details {
    border: 1px solid #ccc;
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 4px;
}

.pdf-viewer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
}

.pdf-viewer-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
}

.pdf-viewer-content {
    position: relative;
    background: #fff;
    padding: 1rem;
    border-radius: 8px;
    max-width: 80% !important;
    max-height: 80% !important;
    overflow: auto;
    z-index: 1001;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #ff4d4d;
    color: #fff;
    border: none;
    padding: 0.5rem;
    border-radius: 4px;
    cursor: pointer;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modal-content {
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    width: 500px;
    max-width: 100%;
    position: relative;
    z-index: 1001;
}

.modal-content .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    cursor: pointer;
}
</style> -->
